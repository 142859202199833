import axios from 'axios';

export const BASE_URL = '../videos.json';


export const fetchFromLocal = async (id) => {
  //console.log('id: ' + id);
  const { data } = await axios.get(`${BASE_URL}`).catch(err=>console.log(err));
  // console.log("fetchFromLocal data: " + JSON.stringify(data));

  if(!id || id === undefined || id === "" || id.length === 0)
  {
    return data;
  }
  else{
    return  data.items.filter(function(item){
      return item.id.videoId.search(
         id) !== -1;      
    });
  }
};
